import { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"

import useSessionStore from "hooks/useSessionStore"

import Auth0ProviderWrapper from "templates/Auth0ProviderWrapper"

const Login = () => {
  const { loginWithRedirect } = useAuth0()
  const email = useSessionStore((s) => s.invite_email)

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: {
        scope: "openid email profile",
        redirect_uri: `${
          process.env.NEXT_PUBLIC_VERCEL_ENV === "preview"
            ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
            : process.env.NEXT_PUBLIC_SPACE_FRONTEND_URL
        }/auth/callback`,
        prompt: "login",
        login_hint: email,
      },
    })
  }, [])

  return null
}

Login.template = Auth0ProviderWrapper

export default Login
