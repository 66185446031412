import { Auth0Provider } from "@auth0/auth0-react"

import type { Children } from "types"

const Auth0ProviderWrapper = ({ children }: Children) => {
  return (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN!}
      clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWrapper
